import bottom from './bottom.png'
import from1 from './from-1.jpg'
import from2 from './from-2.jpg'
import from3 from './from-3.jpg'
import from4 from './from-4.jpg'
import from5 from './from-5.jpg'
import from6 from './from-6.jpg'
import Navbar from '../../components/Navbar'

const Facility
 = () => {

    return(<>
    <section className='max-w-screen background-container'>
<Navbar/>

    <div className="md:w-5/6 w-full h-auto p-4 mx-auto md:p-0 ">
<section  className="md:w-5/6 w-full m-auto my-0 md:my-24 ">
      <span className='hidden md:block'>
      <p className="tblack text-9xl"> FROM BALAJI </p>
      <p className="Kremlin md:text-6xl text-2xl my-2 -translate-y-[5px] md:-translate-y-[50px] text-[#f8af00] rotate-[-4deg]"> With Commitment and Outstanding Excellence      </p>

      </span>
  
        <br></br>
        <br></br>
        <br></br>
      

 <div className='block md:flex'>
 <p className='text-4xl font-bold tblack w-full md:w-1/3 uppercase'>
 From the heart <br></br>of Kenya 
 </p>
 <p className='text-lg w-full md:w-1/2 text-justify'>
 A pristine, natural environment is crucial for producing premium poultry products. We are situated in Nairobi, Kenya, which is uniquely positioned to leverage the city’s harmonious blend of urban and natural landscapes. The clean air and pure water in this setting provides the perfect conditions for crafting poultry products of exceptional quality, embodying the excellence and distinctiveness of Kenyan standards.
 </p>
 </div>
<br></br>
<br></br>
 <div className='block md:flex'>
 <p className='text-4xl font-bold tblack w-full md:w-1/3 uppercase'>
 Cutting-Edge <br></br>Layer Farming
 </p>
 <p className='text-lg w-full md:w-1/2 text-justify'>
 Layer farming began in the early 20th century, transitioning from modest backyard operations to sophisticated commercial enterprises. Initially focused on local egg production, it has since integrated advanced technologies such as automated housing systems, precise nutritional feeding, and real-time monitoring to enhance efficiency and animal welfare. Modern layer farms also employ cutting-edge waste management solutions. Today, layer farming is integral to providing affordable, high-quality eggs for a range of applications, reflecting a blend of historical practices and contemporary innovation.

 </p>
 </div>


</section>

<div className='md:flex md:my-8 md:w-5/6 mx-auto md:translate-x-8'>
    
<div className='md:clippedD p-3 md:p-0 my-4 md:my-0 bg-yellow-500 md:w-1/5 md:p-10 text-center z-40'>
            <p className='font-bold text-xl font-bold tblack uppercase'>
        230 000 m2
        </p>
        <span className='w-2/3 text-xl'>
        The total area of egg production facilities
        </span>
    </div>
    <div className='md:clippedD p-3 md:p-0 my-4 md:my-0 bg-orange-500 text-white md:w-1/5 md:p-10 text-center  transform md:-translate-x-10 z-30'>
            <p className='font-bold text-xl font-bold tblack uppercase'>
           300 000

        </p>
        <span className='w-2/3 text-xl'>
        Hens per unit

</span>
    </div>
    <div className='md:clippedD p-3 md:p-0 my-4 md:my-0 bg-green-500 text-white md:w-1/5 md:p-10 text-center  transform md:-translate-x-16 z-20'>
            <p className='font-bold text-xl font-bold tblack uppercase'>
           12 
            </p>
        <span className='w-2/3 text-xl'>
        Units

</span>
    </div>
    <div className='md:clippedD p-3 md:p-0 my-4 md:my-0 bg-gray-400 text-white md:w-1/5 md:p-10 text-center  transform md:-translate-x-20 z-10'>
            <p className='font-bold text-xl font-bold tblack uppercase'>
            3 600 000
            </p>
        <span className='w-2/3 text-xl'>
        Hens

</span>
    </div>
    <div className='bg-yellow-500 p-3 md:p-0 my-4 md:my-0  md:w-1/5 md:p-10 text-center  transform md:-translate-x-24 z-0'>
            <p className='font-bold text-xl font-bold tblack uppercase'>
            93 600 000
            </p>
        <span className='w-2/3 text-xl'>
        Egg production capacity per year

</span>
    </div>
</div>


<section>
<div className='flex'>
<img className='w-1/2' src={from1} />
<img className='w-1/2' src={from2} />
</div>
<img className='w-full' src={from3} />
</section>
<section>
<div className='flex'>
<img className='w-1/2' src={from4} />
<img className='w-1/2' src={from5} />
</div>
<img className='w-full' src={from6} />
</section>

<img className='w-full mt-24' src={bottom} />
</div>


    </section>

    
    </>)



}

export default Facility
