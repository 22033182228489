import bottom from './bottom.png'
import Navbar from '../../components/Navbar'

const LayerFarming
 = () => {

    return(<>
    <section className='max-w-screen background-container'>
<Navbar/>

    <div className="md:w-5/6 w-full h-auto p-4 mx-auto md:p-0 ">
<section  className="md:w-5/6 w-full m-auto my-0 md:my-24 ">
      <span className='hidden md:block'>
      <p className="tblack text-9xl"> LAYER </p>
      </span>
  
        <p className="Kremlin md:text-6xl text-2xl my-2 -translate-y-[5px] md:-translate-y-[50px] text-[#f8af00] rotate-[-4deg]"> Farming
        </p>
         <br></br>
      

 <p className='text-lg text-justify'>
 Our layer farming focuses on the highest standards of hen welfare and egg quality. To keep our hens healthy and prolific, we provide them a balanced, nutrient-rich meal in addition to large, spotless housing. The quality and safety of eggs are improved by routine veterinarian care and sophisticated management techniques, such as ideal environmental conditions. We guarantee that every egg we produce satisfies exacting criteria of freshness and perfection, which benefits both the environment and our hens by promoting a healthier, happier flock and higher-quality eggs. Our dedication to ethical, ecological methods also helps our hens.

 </p>

</section>

<img src={bottom} />
</div>
    </section>

    
    </>)



}

export default LayerFarming
