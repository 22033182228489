import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import router from './router';

export const top = ReactDOM.createRoot(document.getElementById('top'));
export const root = ReactDOM.createRoot(document.getElementById('root'));
export const bottom = ReactDOM.createRoot(document.getElementById('bottom'));
// top.render(
//   <React.StrictMode>
//     <Navbar />
//   </React.StrictMode>
// );



const URL = window.location.pathname.split("/")
URL.splice(0, 1)
function slugToTitle(slug) {
  slug = slug.replace(/-/g, ' ');
  slug = slug.replace(/\b\w+/g, function(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return slug;
}
router(slugToTitle(URL[0]), root)

  
  


bottom.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>
);
