import bottom from './bottom.png'
import vision1 from './vision-1.png'
import vision2 from './vision-2.png'
import Navbar from '../../components/Navbar'

const Vision = () => {

    return(<>
    <section className='max-w-screen background-container'>
<Navbar/>

    <div className="md:w-5/6 w-full h-auto p-4 mx-auto md:p-0 ">
<section  className="md:w-5/6 w-full m-auto my-0 md:my-24 ">
      <span className='hidden md:block'>
      <p className="tblack text-9xl"> VISION </p>
      </span>
  
        <p className="Kremlin md:text-6xl text-2xl my-2 -translate-y-[5px] md:-translate-y-[50px] text-[#f8af00] rotate-[-4deg]"> and values
        </p>
         <br></br>
      

    <div className="md:flex bg-[#F8AA00]">
        <img src={vision1} className='md:w-1/3 clippedD' />
    <div className="  z-10  text-xl p-8 w-full md:p-16 md:w-full text-justify">
    <p className="tblack text-4xl my-4"> VISION </p>
    We envision a world where industrial farming is both ethical and entirely circular, minimizing waste and maximizing sustainability. By leading through example, we aim to demonstrate how poultry farming can be done responsibly.    </div>
        
    </div>
<br></br>
    <div className="md:flex bg-[#CB451C] text-white">
        
    <div className="  z-10  text-xl p-8 w-full md:p-16 md:w-full text-justify">
    <p className="tblack text-4xl my-4"> MISSION </p>
    Our mission is to supply high-quality, ethically produced eggs through sustainable and humane farming practices. We are committed to minimizing our environmental impact while ensuring the well-being of our hens. By leading with integrity and innovation, we strive to set a new standard in the poultry industry.
    </div>
        <img src={vision2} className='md:w-1/3 clippedD-i' />
    </div>




   <center>
   <p className="Kremlin text-6xl w-auto mx-auto mt-28 text-[#f8af00] rotate-[-4deg]"> Values
   </p>

   </center>
        

   <div className="flex mx-auto border-black border-3  md:w-5/6 ">
   <div  style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="clippedD z-10  mt-16   p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
     
     <center>
        {/* <img src={iconEggCat1} class="h-24 m-8" /> */}
        <p className='tblack mb-4 text-xl md:text-md uppercase'> Quality
        </p>
      
        <p classname="text-sm ">
        We are committed to providing the best eggs possible by upholding the most rigorous standards for nutrition and freshness. Every egg we produce satisfies exacting quality standards thanks to our careful attention to farming techniques, offering unparalleled nutritional value and flavor.


        </p>
        </center>

        </div>
        <div  style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="clippedD z-10  mt-16   p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
     
     <center>
        {/* <img src={iconEggCat2} class="h-24 m-8" /> */}
        <p className='tblack mb-4 text-xl md:text-md uppercase'> Sustainability </p>
        <p classname="text-sm ">
        To reduce our influence on the environment, we adopt eco-friendly procedures. In order to protect the environment and promote the long-term health of agriculture and the community, we are dedicated to sustainable farming, which includes effective resource management, waste minimization, and the use of renewable energy.

        </p>
        </center>

        </div>
        <div  style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="clippedD z-10 mt-16  p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
    
        <center>
            {/* <img src={iconEggCat3} class="h-24 m-8" /> */}
            <p className='tblack mb-4 text-xl md:text-md uppercase'> Animal Welfare            </p>
         <p classname="text-sm ">
        We place a high value on treating our hens humanely and provide them with large, hygienic, and cozy living spaces. Our commitment to animal welfare makes sure that our hens are happy and healthy, which produces high-quality eggs and supports moral agricultural methods.

        </p>
            </center>
    

        </div>
        <div  style={{border:"1px solid grey",}} className="clippedD z-10 mt-16  p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
      
     <center>
        {/* <img src={iconEggCat4} class="h-24 m-8" /> */}
        <p className='tblack mb-4 text-xl md:text-md uppercase'> Integrity  


        </p>
        <p classname="text-sm ">
        We operate using openness, truthfulness, and moral principles. By fostering trust with clients, associates, and the community, our dedication to integrity guarantees that our business practices are accountable, responsible, and consistent with our fundamental principles.
        </p>
        </center>
      

        </div>
   </div>
  

</section>

<img src={bottom} />
</div>
    </section>

    
    </>)



}

export default Vision