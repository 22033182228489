import iconEggCat1 from './icon-egg-cat-1.svg'
import iconEggCat2 from './icon-egg-cat-2.svg'
import iconEggCat3 from './icon-egg-cat-3.svg'
import iconEggCat4 from './icon-egg-cat-4.svg'
import bottom from './bottom.png'
import vision1 from './vision-1.png'
import Navbar from '../../components/Navbar'

const Eggs = () => {

    return(<>
    <section className='max-w-screen background-container'>
<Navbar/>

    <div className="md:w-5/6 w-full h-auto p-4 mx-auto md:p-0 ">
<section  className="md:w-5/6 w-full m-auto my-0 md:my-24 ">
      <span className='hidden md:block'>
      <p className="tblack text-9xl"> Eggs </p>
      </span>
  
        <p className="Kremlin md:text-6xl text-2xl my-2 -translate-y-[5px] md:-translate-y-[50px] text-[#f8af00] rotate-[-4deg]"> Proteinaceous eggs</p>
         <br></br>
      
<p className='text-xl font-bold text-justify'>
Eggs have been a staple in human diets for thousands of years. They were among the earliest foods consumed by civilizations across the globe. It offers a potent blend of vitamins, minerals, and nutrients that are not found in any other meal. In addition to being readily consumable in a matter of minutes, eggs are among the least expensive sources of protein.
</p>
         <br></br>
         <br></br>






         <div className="md:flex mx-auto border-black border-3  md:w-5/6 ">
   <div  style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="clippedD z-10  mt-16   p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
     
     <center>
        <img src={iconEggCat1} class="h-24 m-8" />
        <p className='tblack mb-4 text-xl md:text-md uppercase'> Eye Care
        </p>
        <br></br>
        <p classname="text-sm text-justify ">
        Antioxidants like lutein and zeaxanthin, which are abundant in eggs, are vital for shielding the eyes from damaging light and lowering the risk of cataracts and age-related macular degeneration. Eating eggs on a regular basis can promote long-term eyesight and eye health. 


        </p>
        </center>

        </div>
        <div  style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="clippedD z-10  mt-16   p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
     
     <center>
        <img src={iconEggCat2} class="h-24 m-8" />
        <p className='tblack mb-4 text-xl md:text-md uppercase'> Weight Management </p>
        <p classname="text-sm text-justify ">
        High in protein and healthy fats, eggs can help keep you feeling full and satisfied longer, which may aid in weight management by reducing overall calorie intake. With around 80 calories per big egg, they are a nutrient-dense, low-calorie meal choice that is perfect for anyone trying to gain or maintain their weight.

        </p>
        </center>

        </div>
        <div  style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="clippedD z-10 mt-16  p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
    
        <center>
            <img src={iconEggCat3} class="h-24 m-8" />
            <p className='tblack mb-4 text-xl md:text-md uppercase'> Brain Health            </p>
            <br></br>
        <p classname="text-sm text-justify ">
        Eggs are an ideal supply of choline, a vitamin crucial for cognitive function, brain growth, and preserving the integrity of cell membranes. Acetylcholine, a neurotransmitter essential for memory, mood control, and cognitive function, is derived from choline.   

        </p>
            </center>
    

        </div>
        <div  style={{border:"1px solid grey",}} className="clippedD z-10 mt-16  p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
      
     <center>
        <img src={iconEggCat4} class="h-24 m-8" />
        <p className='tblack mb-4 text-xl md:text-md uppercase'>  Skin and Hair health  


        </p>
        <p classname="text-sm text-justify ">
        Biotin, often known as vitamin B7, is abundant in eggs and is essential for healthy skin and hair. The protein keratin, which increases the suppleness and strength of hair, is produced with the help of biotin. It also lessens hair loss and promotes a healthy scalp.

        </p>
        </center>
      

        </div>
   </div>
  






<h1 className='text-2xl md:text-6xl text-center tblack my-12'>
We Offer

</h1>





    <div className="md:flex bg-[#F8AA00]">
        <img src={vision1} className='md:w-2/5 clippedD' />
    <div className="  z-10  text-md p-8 w-full md:p-16 md:w-full">
    <p className="tblack text-4xl"> Premium Eggs


    </p>
<br></br>
<p className='text-xl'>
We provide high-quality eggs. To guarantee that every egg satisfies the highest standards of freshness, flavor, and nutritional content, our eggs are meticulously chosen and prepared. 


</p>  

      </div>
        
    </div>
<br></br>
    <div className="md:flex bg-[#CB451C] text-white">
        
    <div className="  z-10  text-md p-8 w-full md:p-16 md:w-full">
    <p className="tblack text-4xl"> Guaranteed Freshness


    </p>
<br></br>
<p className='text-xl my-2'>
Every egg you buy is guaranteed to be as fresh as possible because of our dedication to quality. Our eggs are kept as fresh as possible by using strict quality control procedures and maintaining ideal storage conditions.  
</p>  

  </div>
        <img src={vision1} className='md:w-2/5 clippedD-i' />
    </div>


<br></br>

<div className="md:flex bg-[#66723C] text-white">
        <img src={vision1} className='md:w-2/5 clippedD' />
    <div className="  z-10  text-md p-8 w-full md:p-16 md:w-full">
    <p className="tblack text-4xl"> Nutritional Excellence


    </p>
<br></br>
<p className='text-xl'>
Rich in healthy minerals, necessary vitamins, and premium protein, our eggs are a great choice. We put a lot of effort into producing eggs that promote general wellbeing, healthy skin and hair, and cognitive function.
</p>  

      </div>
        
    </div>

 
  

</section>

<img src={bottom} />
</div>
    </section>

    
    </>)



}

export default Eggs