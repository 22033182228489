import Career from "./pages/Career";
import Hens from "./pages/Hens";
import Eggs from "./pages/Eggs";
import LayerFarming from "./pages/Layer Farming";
import Vision from "./pages/Vision";
import Contact from "./pages/Contact";
import Facility from "./pages/Facility";

const { default: Homepage } = require("./pages/Homepage");

const router = (page, root) => {

    const sendToRoot = (dom) => {
        window.history.pushState(null, null, `/${urlToSlug(decodeURIComponent(page))}`)
        root.render(dom)
        window.scrollTo(0, 0)
    }
    switch(decodeURIComponent(page.toLowerCase())){
        case 'home':
            sendToRoot(<Homepage/>)
        break;

        case '':
            sendToRoot(<Homepage/>)
        break;

        case 'vision and values':
            sendToRoot(<Vision/>)
        break;

        case 'layer farming':
            sendToRoot(<LayerFarming/>)
        break;

        case 'facility':
            sendToRoot(<Facility/>)
        break;
        case 'hens':
            sendToRoot(<Hens/>)
        break;
        case 'eggs':
            sendToRoot(<Eggs/>)
        break;

        case 'career':
            sendToRoot(<Career/>)
        break;

        case 'contact':
            sendToRoot(<Contact/>)
        break;

        default:

        break;
    }
  
}
function urlToSlug(url) {
    url = url.replace(/ /g, '-');

    url = url.replace(/[^a-zA-Z0-9-]/g, '');
  
    url = url.toLowerCase();
  
    return url;
  }


export default router