import bottom from './bottom.jpg'
import Navbar from '../../components/Navbar'

const Contact
 = () => {

    return(<>
    <section className='max-w-screen background-container'>
<Navbar/>

    <div className="md:w-5/6 w-full h-auto p-4 mx-auto md:p-0 ">
<section  className="md:w-5/6 w-full m-auto my-0 md:my-24 ">
      <span className='hidden md:block'>
      <p className="tblack text-9xl"> Contact </p>
      </span>
  
        <br></br>
        <br></br>
        <br></br>
      

 <div className='flex'>
 <p className='text-4xl font-bold tblack w-1/2'>
SEND US YOUR CV
 </p>
 <p className='text-lg w-1/2 text-justify'>
In order to expand and strengthen our team, we are always looking for fresh and up-and-coming talent.
<br></br>
<br></br>
Consequently, we would be delighted to speak with you whether you are a fresh graduate who is passionate about your chosen career path or an established expert in your industry. Please send us a note describing your background, experience, and any relevant degrees or certificates you may possess. Your application will be reviewed, and we'll keep you in mind for any future positions that fit your interests and skill set.
<br></br>
<br></br>
<a href='mailto:info@balajipoultry.com' className=' underline text-amber-700 mt-8 pt-4 '>SEND US YOUR CV</a>
 </p>
 </div>


</section>

<img src={bottom} />
</div>
    </section>

    
    </>)



}

export default Contact
