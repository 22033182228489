import { root } from ".."
import router from "../router"

const EggButtonIcon = (text, link)=> {

    return(
        <a className="button button--primary flex " href={link} 
        target="_blank"  onClick={(e)=>{
          e.preventDefault();
          if(link !== null){
            router(link, root)
          }
        }}
        
        // onMouseDown={(e)=>{
        //   e.preventDefault();
        //   let nw = window.open(link, '_blank', 'noopener,noreferrer');
        
        //   if (nw) {
        //     setTimeout(() => {
        //       nw.blur(); // Move focus away from the new window
        //       window.focus(); // Bring focus back to the current window
        //     }, 100); // Delay the focus shift
        //   }
        // }}
        
        >
        {/**/}
        <div className="button__text">
          {text }
        </div>
        <div className="button__icon button__icon--primary">
          <svg
            className="eggs-icon"
            width={34}
            height={22}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 34 22"
          >
            <defs>
              <path
                id="eggs-icon-path"
                fill="#f8af00"
                stroke="rgba(248,135,0,0)"
                strokeWidth={0}
                d="M34.7 37.5c-3 0-5.9-1.4-5.7-6.4.2-4.2 2.9-8.6 5.7-8.6s5.6 4.4 5.7 8.6c.2 4.9-2.7 6.4-5.7 6.4Z"
              />
            </defs>
            <g className="eggs-icon__group" transform="translate(-28.42 -19)">
              <g
                className="eggs-icon__t eggs-icon__t--1 eggs-icon__t--1-to"
                transform="translate(34.7 30)"
              >
                <g className="eggs-icon__t eggs-icon__t--1 eggs-icon__t--1-tr">
                  <g className="eggs-icon__t eggs-icon__t--1 eggs-icon__t--1-ts">
                    <path
                      className="eggs-icon__egg eggs-icon__egg--1"
                      fill="#f8af00"
                      d="M34.7 37.5c-3 0-5.9-1.4-5.7-6.4.2-4.2 2.9-8.6 5.7-8.6s5.6 4.4 5.7 8.6c.2 4.9-2.7 6.4-5.7 6.4Z"
                      transform="translate(-34.7 -30)"
                    />
                  </g>
                </g>
              </g>
              <g
                className="eggs-icon__t eggs-icon__t--2 eggs-icon__t--2-to"
                transform="translate(48.69 30.32)"
              >
                <g
                  className="eggs-icon__t eggs-icon__t--2 eggs-icon__t--2-tr"
                  transform="rotate(384)"
                >
                  <g
                    className="eggs-icon__t eggs-icon__t--2 eggs-icon__t--2-ts"
                    transform="scale(.71)"
                  >
                    <use
                      className="eggs-icon__egg eggs-icon__egg--2"
                      xlinkHref="#eggs-icon-path"
                      strokeWidth={0}
                      transform="translate(-34.7 -30)"
                    />
                  </g>
                </g>
              </g>
              <g
                className="eggs-icon__t eggs-icon__t--3 eggs-icon__t--3-to"
                transform="translate(58.73 31.18)"
              >
                <g
                  className="eggs-icon__t eggs-icon__t--3 eggs-icon__t--3-tr"
                  transform="rotate(680)"
                >
                  <g
                    className="eggs-icon__t eggs-icon__t--3 eggs-icon__t--3-ts"
                    transform="scale(.46)"
                  >
                    <use
                      className="eggs-icon__egg eggs-icon__egg--3"
                      xlinkHref="#eggs-icon-path"
                      strokeWidth={0}
                      transform="translate(-34.7 -30)"
                    />
                  </g>
                </g>
              </g>
              <g
                className="eggs-icon__t eggs-icon__t--4 eggs-icon__t--4-to"
                transform="translate(67.56 33.92)"
              >
                <g
                  className="eggs-icon__t eggs-icon__t--4 eggs-icon__t--4-tr"
                  transform="rotate(863)"
                >
                  <g
                    className="eggs-icon__t eggs-icon__t--4 eggs-icon__t--4-ts"
                    transform="scale(.25)"
                  >
                    <use
                      className="eggs-icon__egg eggs-icon__egg--4"
                      xlinkHref="#eggs-icon-path"
                      strokeWidth={0}
                      opacity={0}
                      transform="translate(-34.7 -30)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </a>
      
      
    )
}

export default EggButtonIcon