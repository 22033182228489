import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import './index.css'; // Import the custom CSS
import logo from '../../media/Top Left Logo.svg'
import menuTransparentRooster from '../../media/menuTransparentRooster.svg'
import router from '../../router';
import { root } from '../..';
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [isOverflowHidden, setIsOverflowHidden] = useState(false);

  const toggleMenu = (bol) => {
    typeof(bol) === "boolean" ? setIsOpen(bol) : setIsOpen(!isOpen);
    setHoveredMenu(null); // Reset hovered menu on close
  };
  const handleClick = (sI, image) => {
    let newOverflowState
    if(image){
      if(isOpen === true){
        toggleMenu() 
        newOverflowState = !isOverflowHidden 
      }else{
        newOverflowState =  isOverflowHidden
      }
    }else{
      toggleMenu() 
      newOverflowState = !isOverflowHidden;
    }

    setIsOverflowHidden(newOverflowState);
    document.body.style.overflow = newOverflowState ? 'hidden' : 'auto';
    
  
    if(sI !== undefined && typeof(sI) === "string"){
      router(sI, root)
    }
  };
  const menuItems = [
    { name: 'Infrastructure', subMenu: ['Layer Farming', "Facility"] },
    { name: 'Products', subMenu: ['Eggs', "Hens"] },
    { name: 'About Us', subMenu: ['Vision and Values', 
      // "Teams"
       ] },
    { name: 'Contact', subMenu: ['Contact'] },
    { name: 'Career', subMenu: ['Contact'] },
  ];
const mouseDown = (sd) => {
  window.open(sd)
}
  return (
    <div className=' overflow-hidden'>
<nav
        className={`flex ${isOpen ? "sticky" : "sticky"} w-full overflow-hidden p-6 md:px-48 duration-300`}
        style={{backgroundColor:"#00000000 !important", zIndex:999999}}
      > 
        

        <img  className='w-28 md:w-40 cursor-pointer' onClick={(e)=>{handleClick("", true)}} src={logo}/>
             <div className="text-2xl font-bold">
      </div>
        <div className="text-2xl cursor-pointer absolute top-5 right-48 flex justify-center items-center w-12 h-12" onClick={toggleMenu}>
          <div className="header-aside__item">
  <button
    className="menu-toggle-button menu-toggle-button--default "
    aria-label="Toggle Menu"
      onClick={handleClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 64 64"
      className={`burger ${isOpen ? 'burger--open' : ''} ml-48 md:ml-0 md:mt-3  `}
      >
      <path
        d="M-28.91 9.66C-27.38-27.72-3.2-67 21.5-67S70.37-27.72 71.9 9.66C73.71 53.46 48.22 66 21.51 66c-26.7 0-52.2-12.55-50.42-56.34Z"
        fill={isOpen ? "#000000" : "#EAB308"}
        className="burger__bg duration-200	"
      />
      <g className="burger__icon duration-200	"

      fill={isOpen ? "#ffffff" : "#ffffff"}>
        <g className="burger-lines" transform="translate(16,16) rotate(0)">
          <g className="burger-lines__el " transform="translate(-15,-15)">
            <g
              className="burger-line burger-line--bottom-to"
              transform="translate(28.5,24.25)"
            >
              <g
                className="burger-line burger-line--bottom-tr"
                transform="rotate(0)"
              >
                <g
                  className="burger-line burger-line--bottom-ts"
                  transform="scale(1,1)"
                >
                  <rect
                    className="burger-line burger-line--bottom duration-7200	"
                    width={!isOpen ? "8.4" : "14"}
                    height="3.5"
                    rx={0}
                    ry={0}
                    transform={!isOpen ? "translate(-8.4,-1.75)" : "translate(-14,-8) rotate(-45)"}
                  />
                </g>
              </g>
            </g>
            <g
              className={`burger-line burger-line--middle-to`}
              transform="translate(20,15)"
            >
              <g
                className="burger-line burger-line--middle-tr"
                transform="rotate(0)"
              >
                <g
                  className="burger-line burger-line--middle-ts"
                  transform="scale(1,1)"
                >
                  <rect
                    className="burger-line burger-line--middle"
                    width="28"
                    height="3.5"
                    rx={0}
                    ry={0}
                    transform={!isOpen ? "translate(-19.5,-1.75)" : "translate(4, 13.5) rotate(-135)"}

                  />
                </g>
              </g>
            </g>
            <g
              className="burger-line burger-line--top-to"
              transform="translate(1.5,5.75)"
            >
              <g
                className="burger-line burger-line--top-tr"
                transform="rotate(0)"
              >
                <g
                  className="burger-line burger-line--top-ts"
                  transform="scale(1,1)"
                >
                  <rect
                    className="burger-line burger-line--top transition-all duration-700 "
                    width={!isOpen ? 27 : 14}
                    height="3.5"
                    rx={0}
                    ry={0}
                    transform={!isOpen ? "translate(0,-1.75)" : "translate(15, 13) rotate(135)"}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
</div>

        </div>
      </nav>
      <div
        className={`fixed top-0 left-0 w-full h-full bg-[#EAB308] flex uppercase md:items-center justify-center z-50 transition-all duration-700 ease-in-out ${
          isOpen ? 'menu-open' : 'menu-close'
        }`}
      >
        <div className="w-full md:w-4/5 max-w-6xl h-3/5 flex rounded-lg mt-10 md:mt-0"  onMouseLeave={() => setHoveredMenu(null)}>
          <div className="w-1/2 item-end text-right p-4 md:p-10 flex flex-col justify-center space-y-6" >
            {menuItems.map((item, index) => (
              <a
                key={index}
                className="text-xl md:text-5xl text-left  cursor-pointer font-bold 	"
                onMouseEnter={() => {
                  setHoveredMenu(null)
                  setTimeout((e)=>{
                    setHoveredMenu(index)}, 100)
                }}
                href={item.name}
                target='_blank'
                onClick={(e)=>{

                  if(item.name === "Contact Us" || item.name === "Career"){
                    handleClick("contact")
                    }else{
                      e.preventDefault()
                    }
                }}
                onMouseDown={

                  (e) => {
                    e.preventDefault()
                    if(item.name === "Contact Us" || item.name === "Career"){
                      mouseDown("contact")
                      }else{
                        e.preventDefault()
                      }
                  }
                }
               
              >
               <span className='hover:border-b-2 border-black tblack border-dashed'>
                {item.name}
                </span> 
              </a>
            ))}
          </div>
          <div className="w-1/2 p-10 flex flex-col item-left justify-center">
          <img src={menuTransparentRooster} draggable={false} className='absolute md:ml-72 bottom-0 ' 
          width={`${window.innerHeight < window.innerWidth ? window.innerHeight * 0.6 : window.innerHeight * 0.2}px`} 
          
          />
            {hoveredMenu !== null && (
              <div className="fade-in-left">
                {menuItems[hoveredMenu].subMenu.map((subItem, subIndex) => (
                  <a key={subIndex} href={subItem} target='_blank'  onClick={(e)=>{
                    e.preventDefault()
                    handleClick(subItem)
                    
                  }} 
                
                  className="text-xl mb-6 md:mb-3">
                    <p 
                   
                    className='text-xl md:text-2xl font-bold cursor-pointer'>
                      <span className=' hover:border-b-2 border-black border-dashed'>
                      {subItem === "Contact" ? "" : subItem === "Career" ? "" : subItem}
                      </span>
                      </p>
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Navbar;
