import { root } from '../..'
import facebook from '../../media/facebook.svg'
import instagram from '../../media/instagram.svg'
import linkedin from '../../media/linkedin.svg'
import router from '../../router'
import logo from '../../media/BP Logo Box.svg'

const Footer = () => {
  const mouseDown = (sd) => {
    window.open(sd)
  }
    return(
        <div className="w-full pt-12 flex items-center justify-center bg-[#cb451c]">
        <div className="md:w-2/3 w-full px-4 text-white flex flex-col">




      <div className="flex">
      <div className="md:mb-4">
        <img src={logo} alt="Logo" className="w-24 md:w-48 h-24 md:h-48"/>
              </div>

              <div className="ml-auto flex mt-6 md:mt-0 gap-1 md:gap-2" >
                <a href='https://www.facebbok.com/'>
                  <img src={facebook} width={32}/>
                </a>
                <a href='https://www.instagram.com/'>
                  <img src={instagram} width={32}/>
                </a>
                <a href='https://www.linkedin.com/'>
                  <img src={linkedin} width={32}/>
                </a>
              </div>
      </div>


        <hr className="border-yellow-900 mt-6" />

          {/* <div className="w-full text-7xl font-bold">
            <h1 className="w-full md:w-2/3">How can we help you. get in touch</h1>
          </div> */}
         
          <div className="flex flex-col">
            <div className="flex   flex-row justify-between">
              
           


            <div className='w-1/2 md:w-2/3  border-r  border-yellow-900'>
            <div className='p-1 md:p-10'>
              <p className='text-lg cursor-pointer font-bold' onMouseDown={(e)=>{
                mouseDown('contacts')
              }} onClick={(e)=>{router('contacts', root)}}>Send us a message</p>
              <br></br>
              <p className=' text-xs md:text-base cursor-pointer '>#PO Box No.7550, Krishna Oasis Apartment, First Park Lands, Starehe District, North of Nairobi, Nairobi, Kenya – 01000</p>
              </div>
            </div>
            <div className='w-1/2 md:w-2/3'>
            <div className='p-1 md:p-10 md:grid-cols-5 md:grid gap-4'>
              <div>
                  <p className='md:my-4 text-lg font-bold'>Infrastructure</p>
                <ul>
                  <li className='cursor-pointer' onClick={(e)=>{ router("Layer Farming",root) }}
                     onMouseDown={(e)=>{
                mouseDown('Layer Farming')
              }} 
                    >Layer Farming</li>
                   <li className='cursor-pointer' onClick={(e)=>{

router("Facility",root)                    

}}>Facility</li>
                </ul>
              </div>

              <div>
                  <p className='md:my-4 text-lg font-bold'>Products</p>
                <ul>
                  <li className='cursor-pointer' onClick={(e)=>{
                    router("Eggs",root) }}
                    onMouseDown={(e)=>{
                      mouseDown('Eggs')
                    }} 
                    >Eggs</li>
                    <li className='cursor-pointer' onClick={(e)=>{router("Hens",root)}}
                     onMouseDown={(e)=>{
                mouseDown('Hens')
              }} 
                    >Hens</li>
                </ul>
              </div>

              <div>
                  <p className='md:my-4 text-lg font-bold'>About us</p>
                <ul>
                  <li className='cursor-pointer' onClick={(e)=>{
                    router("Vision and Values",root)     }}
                    onMouseDown={(e)=>{
                      mouseDown('Vision and Values')
                    }} 
                    
                    >Vision and Values</li>
                  {/* <li className='cursor-pointer' onClick={(e)=>{

                    router("Teams",root)                    

                  }}>Teams</li> */}
                 
                 
                </ul>
              </div>

              <div>
                  <p className='md:my-4 text-lg font-bold cursor-pointer'   onMouseDown={(e)=>{
                    mouseDown('Contact')
                  }}    onClick={(e)=>{

router("Contact",root)                    

}}>Contact us</p>
                 
             
              </div>
              <div>
              <p className='md:my-4 text-lg font-bold cursor-pointer'   onMouseDown={(e)=>{
                    mouseDown('Career')
                  }}    onClick={(e)=>{

router("Career",root)                    

}}>Career</p>
              </div>
              </div>
            </div>



           
            </div>
            <hr className="border-yellow-900" />
            <p className="w-full flex my-12 text-white">
              <span>
              Copyright © 2024 Intredia LLC
              </span>
            </p>
          </div>
        </div>
      </div>
      
      )
}

export default Footer