import bottom from './bottom.png'
import vision1 from './vision-1.jpg'
import Navbar from '../../components/Navbar'

const Hens = () => {

    return(<>
    <section className='max-w-screen background-container'>
<Navbar/>

    <div className="md:w-5/6 w-full h-auto p-4 mx-auto md:p-0 ">
<section  className="md:w-5/6 w-full m-auto my-0 md:my-24 ">
      <span className='hidden md:block'>
      <p className="tblack text-9xl"> Hens </p>
      </span>
  
        <p className="Kremlin md:text-6xl text-2xl my-2 -translate-y-[5px] md:-translate-y-[50px] text-[#f8af00] rotate-[-4deg]"> Kenchik hens</p>
         <br></br>
      
<p className='text-xl font-bold text-justify'>
Kenchic hens, originally developed in Kenya, are a specialized breed renowned for their high egg yield, strong immunity, and adaptability to diverse farming environments.
<br></br>
<br></br>
We firmly think that the health of our hens is the cornerstone to producing eggs that are truly outstanding. Our goal of providing our clients with the best eggs possible is largely dependent on our dedication to hen care. Especially when it comes to our Kenchic hens, they are carefully raised for optimal egg production, characterized by extended production periods and peak yields.
<br></br>
<br></br>
Our commitment to their well-being ensures high livability and robust immunity. The result is strong hens and brown eggs with an extended shelf life, reflecting our dedication to quality care and sustainable practices.
</p>
         <br></br>
         <br></br>






         <div className="md:flex mx-auto border-black border-3  md:w-5/6 ">
   <div  style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="clippedD z-10  mt-16   p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
     
     <center>
        {/* <img src={iconEggCat1} /> */}
        <p className='tblack mb-4 text-xl md:text-md uppercase'> Humane Environment and Housing
        {/* <p className="Kremlin md:text-xlxl text-md rotate-[-4deg] lowercase"> eggs</p> */}
        </p>
        <p classname="text-sm text-justify">
        We provide our poultry with roomy, tidy, and comfortable living quarters. Our facilities are built with animal care in mind, going above and beyond industry norms. Every hen has plenty of room to roam around which lowers stress and improves general health. To maintain a comfortable atmosphere all year round, our housing systems are outfitted with contemporary ventilation and temperature control technologies.

        </p>
        </center>

        </div>
        <div  style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="clippedD z-10  mt-16   p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
     
     <center>
        {/* <img src={iconEggCat2} /> */}
        <p className='tblack mb-4 text-xl md:text-md uppercase'>Balanced Nutrition
        {/* <p className="Kremlin md:text-xlxl text-md rotate-[-4deg] lowercase"> eggs</p> */}

        </p>
<br></br>
        <p classname="text-sm text-justify">
        Providing a healthy, balanced feed is a crucial part of our hen care approach. Our hens' specific nutritional requirements are taken into account while formulating our feed, which guarantees that they get all the vitamins, minerals, and nutrients they require. The nutritional content of our eggs is enhanced by high-quality feed, which also promotes healthy egg production.


        </p>
        </center>

        </div>
        <div  style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="clippedD z-10 mt-16  p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
    
        <center>
            {/* <img src={iconEggCat3} /> */}
            <p className='tblack mb-4 text-xl md:text-md uppercase'> Frequent Health Assessment
{/* 
        <p className="Kremlin md:text-xlxl text-md rotate-[-4deg] lowercase"> eggs</p> */}
        </p>
        <p classname="text-sm text-justify">
        Through routine veterinarian examinations and health monitoring, we place a high priority on the health and wellbeing of our hens. Regular exams are performed by our skilled veterinarians to identify and quickly treat any health concerns. We take preventative care steps to keep our hens healthy and productive, such as immunizations and parasite control.

        </p>
            </center>
    

        </div>
        <div  style={{border:"1px solid grey",}} className="clippedD z-10 mt-16  p-4 md:p-8 w-1/1 item-center justify-center text-center align-middle mx-auto">
      
     <center>
        {/* <img src={iconEggCat4} /> */}
        <p className='tblack mb-4 text-xl md:text-md uppercase'> Sustainable and Ethical Practices
{/* 
        <p className="Kremlin md:text-xlxl text-md rotate-[-4deg] lowercase"> eggs</p> */}

        </p>
        <p classname="text-sm ">
        We use sustainable farming methods that are good for the environment and our hens as part of our commitment to ethical farming. In order to reduce our environmental effect, we put waste management systems in place and, if feasible, use renewable energy sources. By using these strategies, we make sure that our agricultural operations respect the environment and promote long-term sustainability.

        </p>
        </center>
      

        </div>
   </div>






{/* <h1 className='text-2xl md:text-6xl text-center tblack my-12'>
APF offerings

</h1> */}


<br></br>
<br></br>
<br></br>
<br></br>


    <div className="md:flex bg-[#F8AA00]">
        <img src={vision1} className='md:w-2/5 clippedD' />
    <div className="  z-10  text-md p-8 w-full md:p-16 md:w-full">
    <p className="tblack text-4xl"> Flavor and Freshness


    </p>
<br></br>
<p className='text-xl text-justify'>
Eggs are less likely to be exposed to pollutants or spoiling agents when poultry operations prioritize keeping a clean and healthy environment. Longer-lasting eggs are produced by hens kept in hygienic environments. The flavor and texture of fresh eggs are superior, elevating the product's overall quality.



</p>  

      </div>
        
    </div>
<br></br>
    <div className="md:flex bg-[#CB451C] text-white">
        
    <div className="  z-10  text-md p-8 w-full md:p-16 md:w-full">
    <p className="tblack text-4xl"> Nutritional Value 


    </p>
<br></br>
<p className='text-xl my-2 text-justify'>
The provision of a balanced food and appropriate veterinary care are two examples of healthy poultry practices that guarantee hens obtain all the vital nutrients they require. Consequently, this results in eggs that are high in vital vitamins, minerals, and proteins. Better dietary advantages are provided by eggs that are nutritionally balanced, which promotes consumer health. 
</p>  

  </div>
        <img src={vision1} className='md:w-2/5 clippedD-i' />
    </div>


<br></br>

<div className="md:flex bg-[#66723C] text-white">
        <img src={vision1} className='md:w-2/5 clippedD' />
    <div className="  z-10  text-md p-8 w-full md:p-16 md:w-full">
    <p className="tblack text-4xl"> Consistency and Egg Size 


    </p>
<br></br>
<p className='text-xl text-justify'>
Eggs of consistent size and quality are more likely to be produced by well-groomed, healthy chickens on a balanced diet. In order to produce consistent eggs that satisfy customer expectations and requirements for size and appearance, proper nutrition and care are necessary to control egg production.
</p>  

      </div>
        
    </div>

 
  

</section>

<img src={bottom} />
</div>
    </section>

    
    </>)



}

export default Hens