import iconEggCat1 from './icon-egg-cat-1.svg'
import iconEggCat2 from './icon-egg-cat-2.svg'
import iconEggCat3 from './icon-egg-cat-3.svg'
import grain from './grain.webp'
import fpEggShell from './fp-egg-shell.png'
import fpEggShellMD from './fp-egg-shell-md.png'
import LIAA from './LIAA.jpg'
import AnimatedBackgroundSVG from '../../components/HenSVG'
import Navbar from '../../components/Navbar'
import EggButtonIcon from '../../components/eggButtionIcon'
import router from '../../router'
import { root } from '../..'

const Homepage = () => {

    return(<>
    <section className='max-w-screen background-container'>
<Navbar/>
    <AnimatedBackgroundSVG />

    <div className="md:w-5/6 w-full h-auto p-4 mx-auto md:p-0 ">
<section  className="md:w-5/6 w-full m-auto my-0 md:my-24 ">
      <span className='hidden md:block'>
      <p className="tblack md:text-9xl uppercase"> way to a </p>
      <p className="tblack md:text-9xl uppercase">Healthy lifestyle </p>
      </span>
      
      <span className='block md:hidden'>
      <p className="tblack text-4xl text-left uppercase">The way to a  </p>
      <p className="tblack text-4xl text-left uppercase">Wholesome </p>
      <p className="tblack text-4xl text-left uppercase">lifestyle? </p>
      </span>
        <p className="Kremlin md:text-6xl text-2xl my-2 -translate-y-[5px] md:-translate-y-[50px] text-[#f8af00] rotate-[-4deg]"> Proteinaceous & Wholesome </p>
        <p className="text-2xl md:text-md tbold">Hygienic Farming. Healthy Life. High Energy.</p>
        <br></br>
      

    <div className="block md:flex">
    <div className="md:clippedD bg-[#F8AA00] z-10 md:mt-16  text-md p-8 w-full md:p-16 md:w-3/5 text-justify">
    Kenya being East Africa's biggest producer and consumer of eggs, the demand for eggs in the region is increasing, but our vision extends beyond simply supplying eggs. With over <span className='font-bold'>57 acres of Agricultural Land</span>, our goal is to serve as a driving force for improvement in the agriculture industry. We, as<span className='font-black'> Balaji Poultry</span>, are lowering our environmental impact and establishing new standards for environmentally friendly agriculture by investing in cutting-edge techniques and sustainable agricultural methods. These actions fortify the supply chain as a whole and foster the local economy.
<br></br>
<br></br>
As a new generation of farmers with our hearts in the right place, we know industrial farming can be ethical and sustainable. We're on the lookout for new and efficient ways eggs can satisfy the energy needs of people and the planet. A solid moral compass and a no-waste mindset guide our path.
<br></br>
<br></br>
<div className=" pt-6 md:pt-0" onClick={(e)=>{}}>
{EggButtonIcon("More about Our Vision", "vision and values")}

    </div>

        </div>

        <div className=" bg-[#cb451c] mt-16 md:p-20 md:-translate-x-10 text-md h-1/3 p-8 md:w-2/4 text-white  text-justify">
        <h1 className="text-4xl tblack">OUR VISION:</h1>
<br></br>
We envision a totally proteinaceous and healthy industrial agricultural system. We ensure that every Egg and Agro-allied product we sell is a testament to our dedication.<br></br>
        </div>
        
    </div>
   <center>
   <p className="Kremlin text-6xl w-auto mx-auto mt-28 text-[#f8af00] rotate-[-4deg]"> Our Fresh Produce
   </p>
   <div className="md:w-3/6 text-justify text-left mt-10 text-xl leading-relaxed ">
   We recognize the importance of your health and are focused on every factor affecting your food which is why we put our hen's health first by providing them with a healthy feed, hygienic living quarters, and a lot of room to grow. In addition to being tasty and fresh, our eggs are also nutrient-rich because we uphold strict standards in every facet of our business. Explore further to know more.
   </div>
   <br></br>
   <br></br>
   {EggButtonIcon("Click on Our Products below", null)}

   </center>
        

   <div className="flex mx-auto border-black border-3 mt-0 md:w-5/6 ">
   <a href='Eggs' onClick={(e)=>{
    e.preventDefault()
    router("Eggs", root)
   }} style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="cursor-pointer clippedD z-10  mt-8  text-xl p-4 md:p-16 w-1/2 item-center justify-center text-center align-middle mx-auto">
     
     <center > 
        <img src={iconEggCat1} />
        <p className='tblack text-xl md:text-3xl'> EGGS     </p>
        {/* <p className='Kremlin text-2xl'>eggs</p> */}
        </center>

        </a>
        <a href='Hens' onClick={(e)=>{
            e.preventDefault()
            router("Hens", root)
        }} style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="cursor-pointer clippedD z-10 mt-8 text-xl p-4 md:p-16 w-1/2 item-center justify-center text-center align-middle mx-auto">
    
        <center>
            <img src={iconEggCat2} />
            <p className='tblack text-xl md:text-3xl'> HENS      </p>
        {/* <p className='Kremlin text-2xl'>eggs</p> */}
            </center>
    

        </a>
       
   </div>



</section>

<section>
<center>
<p className="Kremlin text-6xl mt-10 md:mt-0 text-[#f8af00] rotate-[-4deg]"> Nutritional Value
</p>
</center>
<div className="block md:flex mx-auto border-black border-3 w-full md:w-5/6 ">
  
   <div  className=" mt-0  text-xl p-2 md:p-16  md:w-1/3  text-left mx-auto">
     
    <div className='my-6'>
        <h1 className='text-xl md:text-4xl tblack'>
        1. Powerhouse Nutrition 


        </h1>
        <p className='text-base md:text-md text-justify text-left my-4'>
        Eggs are an excellent source of high-quality protein as they provide all nine essential amino acids, which are critical for healthy muscle development and repair.        </p>
    </div>
    
    <div className='my-6'>
        <h1 className='text-xl md:text-4xl tblack'>
       3. Nutritional Information
        </h1>
        <p className='text-base md:text-md text-left my-4'>
       <span className='block'>Per 100g - </span>
       <span className='block'>Energy value: 132 kcal / 549 kJ</span>
       <span className='block'> Carbohydrates: 1.3% </span>
       <span className='block'>Sugar: {`<1%`}</span>
       <span className='block'> Proteins: 12.5%</span>
       <span className='block'>Salt: 0.4g </span>
       <span className='block'> Fats: 8.5%</span>
       <span className='block'> Saturated fatty acids: 31.2%</span>
      </p>
    </div>
        </div>
        
        
        <div   className="">
    
        
            <img src={window.innerHeight > window.innerWidth ? fpEggShell : fpEggShellMD}  className='md:mt-32'/>
          
    

        </div>



        
        <div  className=" mt-0  text-xl md:p-16 p-4 w-full md:w-1/3  text-left mx-auto">
      
    <div className='my-6'>
        <h1 className='text-xl md:text-4xl tblack'>
        2. Wellness 

 

        </h1>
        <p className='text-base md:text-md  text-left my-4'>
        Eggs packed in good sources of omega-3 fatty acids; promotes cardiovascular health. Antioxidants like lutein and zeaxanthin are vital lowering the risk of cataracts and age-related macular degeneration
      </p>
    </div>
    <div className='my-6'>
        <h1 className='text-xl md:text-4xl tblack'>
        4. Egg Nutritional Value

        </h1>
        <p className='text-base md:text-md text-justify text-left my-4'>
        Among the most nutrient-dense foods available, eggs stand out. Vitamins A, B2, B6, B12, and D are all present in eggs. Eggs are rich sources of selenium, an important antioxidant that is necessary for healthy thyroid function, immune system function, and mental wellness.        </p>
    </div>
   
        </div>
   </div>
</section>


<section>
<center>
   <p className="tblack text-4xl md:text-6xl w-auto mx-auto mt-10"> OUR APPROACH</p>
   <p className="Kremlin text-4xl md:text-6xl w-auto mx-auto  text-[#f8af00] rotate-[-4deg]"> to layer farming
   </p>
   <img src={grain} className='hidden md:block md:absolute  scale-75 -translate-x-72 -z-2'/>
<p className='mt-10 md:w-2/5 mb-10 text-justify text-lg text-left'>
Since perfect hygiene methods are the cornerstone of great egg production, we have developed a thorough methodology to guarantee that every egg we give satisfies the highest requirements of safety and cleanliness. Our layer farming facilities are designed with hygiene at the forefront.

<br></br>
<br></br>

 We have invested in state-of-the-art infrastructure that includes advanced ventilation systems, Environmentally Controlled (EC) equipments, and regular sanitation protocols. Every facility is subjected to thorough cleaning and disinfection processes on a regular basis to guarantee that all surfaces, tools, and living spaces are free of impurities and dangerous micro-organisms.<br></br>
<br></br>
{/* Just as all parts of an egg are a valuable resource, the waste and byproducts from egg production are also an opportunity to recycle, create value, and close the production loop. We call this a no-waste mindset, and we see it as our responsibility and duty toward the environment and a more sustainable way of farming. */}
</p>
{/* <p className='text-sm md:w-2/5 text-left my-10'>
Akciju Sabiedrība “APF Holdings” ir Eiropas Savienības specifiskā atbalsta mērķa “Sekmēt mazo, vidējo komersantu izveidi un attīstību, īpaši apstrādes rūpniecībā un RIS3 prioritārajās nozarēs” 3.1.1.3. pasākuma “Atbalsts mazo, vidējo komersantu finansējuma piesaistei kapitāla tirgos” finansējuma saņēmējs. Uzņēmums aktīvi strādā, lai 2023. gada laikā emitētu akcijas NASDAQ First North Alternatīvajā tirgū. 
<br></br>
<br></br>
2023.gada 13.oktobrī  tika uzsākts Akciju Sabiedrība “APF Holdings” akciju sākotnējais publiskais piedāvājums (IPO jeb Initial Public Offering) un tas noslēdzās 2023.gada 3. novembrī, piesaistot  107 % no investīciju mērķa, jeb 5 624 204 eiro.

</p> */}
   </center>
   <center>
   {EggButtonIcon("More about our approach", null)}

   </center>
   <div className="flex mx-auto border-black border-3 mt-0 md:w-5/6 ">
   <a href='layer Farming' onClick={(e)=>{
    e.preventDefault()
    router("layer Farming", root)
   }} style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="cursor-pointer clippedD z-10  mt-8  text-xl p-4 md:p-16 w-1/2 item-center justify-center text-center align-middle mx-auto">
     
     <center > 
        <img src={iconEggCat1} />
        <p className='tblack text-xl md:text-3xl'> Layer Farming     </p>
        {/* <p className='Kremlin text-2xl'>eggs</p> */}
        </center>

        </a>
        <a href='Facility' onClick={(e)=>{
            e.preventDefault()
            router("Facility", root)
        }} style={{border:"1px solid grey", borderCollapse:"collapse", borderRight: "0"}} className="cursor-pointer clippedD z-10 mt-8 text-xl p-4 md:p-16 w-1/2 item-center justify-center text-center align-middle mx-auto">
    
        <center>
            <img src={iconEggCat2} />
            <p className='tblack text-xl md:text-3xl'> Facility      </p>
        {/* <p className='Kremlin text-2xl'>eggs</p> */}
            </center>
    

        </a>
       
   </div>
</section>

<br></br>
<br></br>
<br></br>

</div>
    </section>

    
    </>)



}

export default Homepage